import React from 'react'

import PropTypes from 'prop-types'

import './use-cookies-bar.css'

const UseCookiesBar = (props) => {
  return (
    <div id="usecookiesbar" className="use-cookies-bar-container">
      <div className="use-cookies-bar-container1">
        <a
          href="https://delaysoft.com/cookiespolicy#nbc"
          className="use-cookies-bar-link"
        >
          {props.useCookiesBarTitle}
        </a>
      </div>
      <div className="use-cookies-bar-container2">
        <button
          id="usecookiesbarclosebutton"
          className="use-cookies-bar-button button"
        >
          {props.useCookiesBarButtonTitle}
        </button>
      </div>
    </div>
  )
}

UseCookiesBar.defaultProps = {
  useCookiesBarButtonTitle: 'Close',
  useCookiesBarTitle:
    'We use cookies to provide you with a better experience. By continuing to use our site you accept our  Cookies Policy',
}

UseCookiesBar.propTypes = {
  useCookiesBarButtonTitle: PropTypes.string,
  useCookiesBarTitle: PropTypes.string,
}

export default UseCookiesBar
