import React from 'react'

import PropTypes from 'prop-types'

import UseCookiesBar from './use-cookies-bar'
import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-container">
        <div className="footer-container1">
          <a href="https://delaysoft.com" className="footer-link">
            <img
              alt={props.image_alt}
              src="/playground_assets/delaysoft-logo-white.svg"
              className="footer-image"
            />
          </a>
          <span>{props.copyrighttext}</span>
        </div>
        <div className="footer-container2">
          <div className="footer-container3">
            <span className="footer-text1 large">
              <span>Contacts</span>
            </span>
            <a
              href="mailto:contact@delaysoft.com?subject="
              className="footer-link1"
            >
              {props.text}
            </a>
            <a
              href="https://delaysoft.com/cookiespolicy#nbc"
              className="footer-link2"
            >
              {props.CookiesPolicyLinkText}
            </a>
          </div>
          <div className="footer-container4">
            <span className="footer-text3 large">Products</span>
            <a
              href="https://tapringer.com"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-link3"
            >
              {props.text1}
            </a>
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/playground_assets/waves-white.svg"
        className="footer-image1"
      />
      <UseCookiesBar></UseCookiesBar>
    </footer>
  )
}

Footer.defaultProps = {
  image_src1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt: 'image',
  text: 'contact@delaysoft.com',
  text1: 'TapRinger',
  link_text: 'https://example.com',
  copyrighttext: 'Copyright © 2022 DelaySoft OÜ',
  CookiesPolicyLinkText: 'Cookies Policy',
  link_text1: 'https://example.com',
  image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
}

Footer.propTypes = {
  image_src1: PropTypes.string,
  image_alt: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  link_text: PropTypes.string,
  copyrighttext: PropTypes.string,
  CookiesPolicyLinkText: PropTypes.string,
  link_text1: PropTypes.string,
  image_src: PropTypes.string,
}

export default Footer
