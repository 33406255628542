import React from 'react'

import PropTypes from 'prop-types'

import './header1.css'

const Header1 = (props) => {
  return (
    <div id="nbc" data-role="Header" className="header1-header">
      <nav className="header1-nav">
        <div className="header1-container">
          <a href="https://delaysoft.com" className="header1-link">
            <img
              alt={props.Image_alt}
              src={props.Image_src}
              className="header1-image"
            />
          </a>
          <div className="header1-menu">
            <a
              href="https://delaysoft.com/#products_container_id"
              className="header1-link1 large"
            >
              {props.text}
            </a>
            <a
              href="https://delaysoft.com/#footer_container_id"
              className="header1-link2 large"
            >
              {props.text1}
            </a>
          </div>
          <div className="header1-container1">
            <div data-type="BurgerMenu" className="header1-burger-menu">
              <svg viewBox="0 0 1024 1024" className="header1-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
      <div data-type="MobileMenu" className="header1-mobile-menu">
        <div className="header1-top">
          <img
            alt={props.Image_alt1}
            src={props.Image_src1}
            className="header1-image1"
          />
          <div data-type="CloseMobileMenu" className="header1-close-menu">
            <svg viewBox="0 0 1024 1024" className="header1-icon2">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        <div className="header1-mid">
          <div className="header1-menu1">
            <a href="#products_container_id" className="header1-link3 large">
              {props.text2}
            </a>
            <a href="#footer_container_id" className="header1-link4 large">
              {props.Link}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Header1.defaultProps = {
  text2: 'Products',
  Link: 'Contacts',
  Image_src: '/playground_assets/delaysoft-logo.svg',
  Image_src1: '/playground_assets/delaysoft-logo.svg',
  text1: 'Contacts',
  Image_alt1: 'image',
  Image_alt: 'image',
  text: 'Products',
}

Header1.propTypes = {
  text2: PropTypes.string,
  Link: PropTypes.string,
  Image_src: PropTypes.string,
  Image_src1: PropTypes.string,
  text1: PropTypes.string,
  Image_alt1: PropTypes.string,
  Image_alt: PropTypes.string,
  text: PropTypes.string,
}

export default Header1
