import React from 'react'

import { Helmet } from 'react-helmet'

import Header1 from '../components/header1'
import PrimaryPinkButton from '../components/primary-pink-button'
import OutlineGreenButton from '../components/outline-green-button'
import ListItem from '../components/list-item'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>DelaySoft</title>
        <meta property="og:title" content="DelaySoft" />
      </Helmet>
      <Header1></Header1>
      <div className="home-hero">
        <div className="home-container01">
          <div className="home-card">
            <h1 className="home-text headingOne">
              <span>Simple solutions</span>
            </h1>
            <h1 className="home-text2 headingOne">for complex issues</h1>
            <span className="home-text3 lead">
              <span>
                As a Team of professional IT specialists we develop and
                distribute software which brings simplicity in a world of
                complex solutions and technologies.
              </span>
            </span>
            <div className="home-container02">
              <div className="home-container03">
                <a
                  href="mailto:contact@delaysoft.com?subject="
                  className="home-link"
                >
                  <PrimaryPinkButton
                    button="contact us"
                    className="home-component1"
                  ></PrimaryPinkButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/playground_assets/preview-1500h.jpg"
        className="home-image"
      />
      <section id="products_container_id" className="home-products-contaier">
        <div className="home-container04">
          <h2 className="home-text5 headingTwo">Our products</h2>
        </div>
        <div className="home-container05">
          <div className="home-container06">
            <div className="home-container07"></div>
            <div className="home-container08">
              <img
                alt="image"
                src="/playground_assets/tapringer-logo-trnsp%403x-200h.png"
                className="home-image1"
              />
              <span className="home-text6">
                <span>Cheap international and domestic phone calls</span>
              </span>
              <img
                alt="image"
                src="/playground_assets/phone_eng%403x1-300w.png"
                className="home-image2"
              />
              <a
                href="https://tapringer.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <OutlineGreenButton
                  button="get started"
                  rootClassName="outline-green-button-root-class-name"
                  className="home-component2"
                ></OutlineGreenButton>
              </a>
            </div>
          </div>
          <div className="home-container09">
            <ListItem description="Cheap international calls with great call quality. Free calls within the network and to more than 100 countries in the world."></ListItem>
            <ListItem
              title="2. Easy to use"
              description="Application for mobile phones is easy to use. Registration takes less than 5 minutes."
            ></ListItem>
            <ListItem
              title="3. Virtual Numbers"
              description="Buy your own phone numbers from 100 countries  and make a calls or receive SMS like with regular phone."
            ></ListItem>
          </div>
        </div>
      </section>
      <div id="footer_container_id" className="home-footer-container">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Home
